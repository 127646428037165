import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, Card, Flex, Box, Link, Message } from 'theme-ui';
import { PageStructure } from '../../components/help-center/PageStructure';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageStructure layoutProps={props} title="Getting Started" next={{
  name: 'Account Setup',
  path: '/account-setup'
}}>
        {props.children}
    </PageStructure>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`If you are new to Simple Review Management you are in the right place!`}</p>
    <p>{`This article is the first in a series that will help you get Simple RM up and running.`}</p>
    <Message sx={{
      mb: 4
    }} mdxType="Message">
To move to the next article in the series follow the 'Next Article' button link at the bottom right of the article.
    </Message>
    <p>{`The next article in the series will cover `}<a parentName="p" {...{
        "href": "/help-center/account-setup/"
      }}>{`account setup`}</a>{`. If you do not yet have an account start there.`}</p>
    <p>{`Once your account is set up and you are familiar with the `}<a parentName="p" {...{
        "href": "/help-center/account-dashboard-intro/"
      }}>{`account dashboard`}</a>{` you can start to `}<a parentName="p" {...{
        "href": "/help-center/send-review-requests/"
      }}>{`send review requests`}</a>{`.`}</p>
    <p>{`If you require assistance at any point don’t hesitate to `}<a parentName="p" {...{
        "href": "/contact"
      }}>{`contact us`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      